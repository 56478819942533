import React from "react";



class CatSelect extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }
    render() {
      const theWholeField = (
          <select onChange={this.props.updatecat} defaultValue={this.props.cat}>
            <option>{this.props.nullspace?this.props.nullspace:"Select A Category"}</option>
              {
                  this.props.categories.map(cat => {
                      return (
                          <option value={cat}>{cat}</option>
                      )
                  })
              }
          </select>
      );
      return theWholeField;
    }
}



export default CatSelect;