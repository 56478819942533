import React from "react";
import ImageWithToggle from "./imagediagnoserimage";

class FromDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    
    // if(this.props.isloading)
    // {
    //     return (<ReactLoading type="spin" color="black" />);
    // }

    const theWholeField = (
        <div className="fromdiv" onScroll={this.props.infiniteScroll}>
        {
            this.props.images.map(image => {
                return (
                    <ImageWithToggle key={Math.random(50000)} fromimg={true} selectedImages={this.props.selectedImages} imageurl={image} selected={this.props.selected} toggleEdit={this.props.toggleEdit} updateSelected={this.props.updateSelected}></ImageWithToggle>
                )
            })
        }
      </div>
    );
    return theWholeField;
  }
}

export default FromDiv;