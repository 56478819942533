import React from "react";
import './global';


class ImageThumb extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }
    setselectedimage = e => {
        let newurl = e.target.src;
        this.props.setselectedimage(newurl);
    }

    hoversetselectedimage = e => {
        let newurl = e.target.src;
        this.props.hoversetselectedimage(newurl);
    }

    render() {
      const theWholeField = (
          <div className={this.props.isIn?"greythum":"thumbnail"} >
              <img alt="title" src={this.props.imageloc} onMouseOver={this.hoversetselectedimage} onClick={this.setselectedimage}/>
          </div>
      );
      return theWholeField;
    }
  }
  export default ImageThumb;