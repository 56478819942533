import React from "react";
import ImageThumb from "./imagespeedsorterimagethumb";

import ImageWithToggle from "./imagediagnoserimage";
class ImageRows extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }
    render() {
      const theWholeField = (
          <div className="imagerows" onScroll={this.props.infiniteScroll}>
              {
                this.props.rows.map(row => {
                    return(
                      <ImageThumb isIn={this.props.selectrows.find(im=> im.replace(/%20/g, " ") === row.replace(/%20/g, " "))} hoversetselectedimage={this.props.hoversetselectedimage} setselectedimage={this.props.setselectedimage} imageloc={row} thumbsize={this.props.thumbsize} />
                    // <ImageWithToggle fromimg={true} selectedImages={[]} imageurl={row} selected={[]} toggleEdit={()=>{console.log("toggled")}} updateSelected={this.props.setselectedimage}></ImageWithToggle>
                  )  
              })}
          </div>
      );
      return theWholeField;
    }
  }
    
export default ImageRows;