import React from "react";
import ImageRows from "./imagespeedsorterimagerows";
import ImageViewer from "./imagespeedsorterimageviewer";
import CatSelect from "./imagespeedsortercatselect";
import ReactLoading from 'react-loading';
import "./imagespeedsorter.css";
import "./global";

class imagespeedsorter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedimagepath: "",
      selectedimages: [],
      alertSelected: "",
      rows: [],
      cats: [],
      thumbsize: "calc(10% - 10px)",
      percentage: 10,
      category: "",
      categories: [],
      length: 20,
      page: 0,
    };
  }

  updatelocation = (action) => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Files: this.state.selectedimages,
        Key: action,
      }),
    };
    fetch(window.server + "api/sorter/update.php", requestOptions)
    .then((res) => res.json())
    .then((result) => {
        if(result.success)
        {
            let newRows = this.state.rows.filter(row=> !this.state.selectedimages.find(img => img.replace(/%20/g, " ") === row ));
            console.log("lst: " + this.state.selectedimages[0].replace(/%20/g, " "));
            console.log(newRows)
            this.setState({
                rows:newRows,
                selectedimages:[],
            })    
        }
      }
    );
  };

  handleKeyDown = (event) => {    
      switch (event.key) {
        case event.key:
          if (event.key === "f") {
            this.setState({
              alertSelected: "Fix",
            });
            this.updatelocation("Fix");
          } else if (event.key === "d") {
            this.setState({
              alertSelected: "Delete",
            });
            this.updatelocation("Delete");
          } else if (event.key === "c") {
            this.setState({
              alertSelected: "Correct",
            });
            this.updatelocation("Clean");
          }
          //fetch move image(update)
          break;
        default:
          break;
      }
  };

  componentDidMount() {
    // this.props.setMenu(false);
    document.addEventListener("keydown", this.handleKeyDown, false);
    this.setState({
        isloading:true
    })
    fetch(window.server + "api/sorter/readdirty.php?Cats=1")
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          categories: result,
        });
      });

    fetch(window.server + "api/sorter/readdirty.php?Cat=" + this.state.category)
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          cats: result,
          // rows:result[0].files
        });
      }).finally(() => {
        this.setState({
            isloading:false
        })
    });
  }

  setselectedimage = (image) => {
    this.setState(
      {
        selectedimagepath: image,
        selectedimages: [...this.state.selectedimages, image],
      },
      () => {
        console.log("This:" + this.state.selectedimages);
      }
    );
  };

  removesetselectedimage = (image) => {
    let images = this.state.selectedimages.filter(img=>img !== image);
    this.setState(
      {
        selectedimagepath: image,
        selectedimages: images
      },
      () => {
        console.log("This:" + this.state.selectedimages);
      }
    );
  };
  hoversetselectedimage = (image) => {
    this.setState(
      {
        selectedimagepath: image,
        // selectedimages: [...this.state.selectedimages, image],
      }
    );
  };

  setThumbSize = (e) => {
    let percent = e.target.value;
    let newPercent = "calc(" + percent + "% - 10px)";
    this.setState({
      thumbsize: newPercent,
      percentage: percent,
    });
  };

  //   updatecat = e => {
  //     let cat = e.target.value;
  //     let rows = this.state.cats.find(cate => cate.cat === cat).files;
  //     this.setState({
  //         category:cat,
  //         rows:rows,
  //     })
  // }

  infiniteScroll = (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      // alert("Scrolled");
      this.addNewImages();
    }
  };

  addNewImages = () => {
    this.setState({
      isloading: true,
    });
    document.body.style.cursor = "wait !important";
    fetch(
      window.server +
        "api/sorter/readdirty.php?disease=" +
        this.state.category +
        "&length=" +
        this.state.length +
        "&page=" +
        this.state.page
    )
      .then((res) => res.json())
      .then((result) => {
        // let newImages = result.out.map((img) => img.substring(3));
        // let newestimages = [...new Set([...this.state.rows, ...newImages])];
        let newImages = result.out.map(img => window.server + "imageuploads/" + img);    
        this.setState(
          {
            // rows: newestimages,
            rows: [...new Set([...this.state.rows, ...newImages])],
            page: this.state.page + 1,
          },
          () => {}
        );
      })
      .finally(() => {
        document.body.style.cursor = "default";
        this.setState({
          isloading: false,
        });
      });
  };
  updatecat = (e) => {
    let cat = e.target.value;

    fetch(window.server + "api/sorter/readdirty.php?disease=" + cat +
    "&length=" +
    this.state.length +
    "&page=" + 0)
      .then((res) => res.json())
      .then((result) => {
        let newImages = result.out.map(img => window.server + "imageuploads/" + img);
        this.setState({
          category: cat,
          rows: newImages,
          page: 1,
          selectedimages:[],
        });
      });
  };

  render() {
    const theWholeField = (
    <div className='objectField'>
        <div className="mainbody">   
        <div className="loadingBar">     
            <div className="loadingnon">
                <div style={{margin:"0 auto",width:"64px"}}>
                    <ReactLoading type="spin" color={this.state.isloading?"red":"white"}/>
                </div>
                </div>
                    {/* <input
                    type="number"
                    value={this.state.percentage}
                    onChange={this.setThumbSize}
                    /> */}
                    <button className="key-labels" onClick={()=>{this.updatelocation("Delete")}}>
                        (d)elete 
                    </button>
                    <button className="key-labels" onClick={()=>{this.updatelocation("Clean")}}>
                        (c)orrect 
                    </button>
                    <button className="key-labels" onClick={()=>{this.updatelocation("Fix")}}>
                        (f)ix/incorrect
                    </button>
                    <CatSelect
                    updatecat={this.updatecat}
                    cat={this.state.category}
                    categories={this.state.categories}
                    />
                </div> 
                <ImageViewer
                    selectedimagepath={this.state.selectedimagepath}
                    infiniteScroll={this.infiniteScroll}
                    setselectedimage={this.removesetselectedimage}
                    hoversetselectedimage={this.hoversetselectedimage}
                    rows={this.state.selectedimages}
                    thumbsize={this.state.thumbsize}
                />
                <ImageRows
                    infiniteScroll={this.infiniteScroll}
                    setselectedimage={this.setselectedimage}
                    hoversetselectedimage={this.hoversetselectedimage}
                    rows={this.state.rows}
                    thumbsize={this.state.thumbsize}
                    selectrows={this.state.selectedimages}
                />

            </div>
        </div>
    );
    return theWholeField;
  }
}

export default imagespeedsorter;
