import React from "react";

class ImageWithToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const theWholeField = (
      <div key={Math.random(50000)} className={((this.props.selectedImages.indexOf(encodeURI(this.props.imageurl)) > -1) && this.props.fromimg)?"imagetoggledis" :"imagetoggle"}>
        <img key={Math.random(50000)} className={((this.props.selectedImages.indexOf(encodeURI(this.props.imageurl)) > -1) && this.props.fromimg)?"imagetoggleimgdis" :"imagetoggleimg"}
            id={this.props.imageurl} 
            src={this.props.imageurl} 
            alt="test" 
            onClick={((this.props.selectedImages.indexOf(encodeURI(this.props.imageurl)) > -1) && this.props.fromimg)?null:this.props.updateSelected}/>
        {/* <label key={Math.random(50000)} className="switch">
            <input key={Math.random(50000)} name={decodeURI(this.props.imageurl)} type="checkbox" onChange={this.props.toggleEdit.bind(this)} checked={this.props.selected.indexOf(decodeURI(this.props.imageurl)) > -1}/>
            <span key={Math.random(50000)} className="slider round"></span>
        </label> */}
      </div>
    );
    return theWholeField;
  }
}

export default ImageWithToggle;