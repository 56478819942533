import React from "react";
import './global';

class ImageView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbsize: "calc(10% - 10px)",
            percentage: 10,
            length:10,
            page:1,
        }
    }


    setThumbSize = e => {
        let percent = e.target.value;
        let newPercent = "calc(" + percent + "% - 10px)";
        this.setState({
            thumbsize: newPercent,
            percentage: percent,
        })
    }

    updatecat = e => {
        let cat = e.target.value;
        this.props.updateCat(cat);
    }
    
    infiniteScroll = (e) => {
        const target = e.target;
        if(target.scrollHeight - target.scrollTop <= target.clientHeight)
        {
            // alert("Scrolled");
            this.props.addNewImages();
        }
    }


    render() {
        const theWholeField = (
            <div className="mainbody">
                {/* <p>{this.props.passedImage + (this.props.passedImage === this.state.selectedImageInt?"yes":"no") + this.state.selectedImageInt}</p> */}
                <CatSelect updatecat={this.updatecat} cat={this.props.cat} categories={this.props.cats} />
                {/* <input type="number" value={this.state.percentage} onChange={this.setThumbSize} /> */}
                <ImageRows active={this.props.src[this.props.passedImage]} setselectedimage={this.props.setselectedimage} updateThisOne={this.props.lastimage!==undefined?this.props.lastimage:""} rows={this.props.src} thumbsize={this.state.thumbsize}  infiniteScroll={this.infiniteScroll}/>
                {/* <ImageRows setselectedimage={this.setselectedimage} updateThisOne={this.props.passedImage === this.state.selectedImageInt?"":this.state.selectedimagepath} rows={this.props.src} thumbsize={this.state.thumbsize}  infiniteScroll={this.infiniteScroll}/> */}
            </div>
        );
        return theWholeField;
    }
}


class CatSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const theWholeField = (
        <select onChange={this.props.updatecat} defaultValue={this.props.cat}>
            <option>Please Select A Category</option>
            {
                this.props.categories.map(cat => {
                    return (
                        <option value={cat}>{cat}</option>
                    )
                })
            }
        </select>
    );
    return theWholeField;
  }
}

  

class ImageRows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const theWholeField = (
        <div className="imagerowss" id="rowdiv" onScroll={this.props.infiniteScroll}>
            {this.props.rows.map(row => {
                return(
                    <ImageThumb active={this.props.active} updateThisOne={this.props.updateThisOne} setselectedimage={this.props.setselectedimage} imageloc={row} thumbsize={this.props.thumbsize}  />
                )  
            })}
        </div>
    );
    return theWholeField;
  }
}
  

class ImageThumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  setselectedimage = e => {
      let newurl = e.target.src;
    //   document.getElementById("displayimg").src=newurl;
      console.log("New: " + newurl)
      this.props.setselectedimage(newurl);
  }
  render() {
    const theWholeField = (
        <div className={this.props.imageloc===this.props.active?"selctedThumb":"thumbnail"} >
            {/* <p>{this.props.updateThisOne}</p> */}
            {/* <p>{"/imageclean/" + this.props.updateThisOne.substring(0,this.props.updateThisOne.indexOf("?")).replace(/%20/g, " ") + " === " + this.props.imageloc.substring(7).substring(0,this.props.imageloc.substring(7).indexOf("?"))}</p> */}
            <img 
                alt="title" 
                id={window.server + "cropper/" + this.props.imageloc.substring(2,this.props.imageloc.indexOf('?'))} 
                src={window.server + this.props.imageloc} 
                onClick={this.setselectedimage}/>
        </div>
    );
    return theWholeField;
  }
}

export default ImageView;