// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';


function HomePage() {
    const [cats, setcategories] = useState({})
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(window.server + "api/overwatch/read.php").then(res => res.json()).then(result => {
            setcategories(result)
        }).finally(() => {
            setLoading(false);
        })
    },[])

  return (
            <div>
                <h1>Overwatch</h1>
                {(loading?
                    <div className='balls'>
                        <ReactLoading type={"balls"} color={"red"} />
                    </div>
                    :<>
                    <p>Uploaded/SpeedSorter: {cats.uploaded}</p>
                    <p>Diagnosable: {cats.editable}</p>
                    <p>Clean/Crop: {cats.clean}</p>
                    <p>Finalized/AI: {cats.finalized}</p>
                </>)}
            </div>
  );
}

export default HomePage;
