// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Cropper from './imagecropper';
import SpeedSorter from './imagespeedsorter';
import ImageDiagnoser from './imagediagnoser';
import HomePage from './HomePage';
import RenameInputFolders from './renameinputfolders';
import CleanReview from './imageclean';
import FinalizedReview from './imagefinalized';


function App() {
  return (
        <Router>
            <div className="navbar">
                <div className="navbar-image">
                    <Link to="/">
                            <img src={"./img_logo.png"} alt="Skin Exam Logo" />
                    </Link>
                </div>
                {/* <Link to="/finalizedreview">Finalized Review</Link> */}
                <Link to="/cleanreview">Clean Review</Link>
                <Link to="/cropper">Crop</Link>
                <Link to="/diagnoser">Diagnose</Link>
                <Link to="/sorter">Speed Sort</Link>
                <Link to="/rename">Rename Input Folders</Link>
                <Link to="/">Home</Link>
            </div>
            <Switch>
                <Route exact path="/">
                    <HomePage ></HomePage>
                </Route>
                <Route path="/rename">
                    <RenameInputFolders></RenameInputFolders>
                </Route>
                <Route path="/cropper">
                    <Cropper ></Cropper>
                </Route>
                <Route path="/sorter">
                    <SpeedSorter ></SpeedSorter>
                </Route>
                <Route path="/diagnoser">
                    <ImageDiagnoser></ImageDiagnoser>
                </Route>
                <Route path="/cleanreview">
                    <CleanReview></CleanReview>
                </Route>
                <Route path="/finalizedreview">
                    <FinalizedReview></FinalizedReview>
                </Route>
            </Switch>
        </Router>
  );
}

export default App;
