import React from "react";
import CatSelect from "./imagespeedsortercatselect";
import ReactLoading from "react-loading";
import "./imagespeedsorter.css";
import "./global";

class RenameInputFolders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cleanCat: "",
            cleanCats: [],
            selectedimagepath: "",
            selectedimages: [],
            alertSelected: "",
            rows: [],
            cats: [],
            thumbsize: "calc(10% - 10px)",
            percentage: 10,
            category: "",
            categories: [],
            length: 20,
            page: 0,
        };
    }

    componentDidMount() {
        // this.props.setMenu(false);
        document.addEventListener("keydown", this.handleKeyDown, false);
        this.setState({
            isloading: true,
        });
        fetch(window.server + "api/sorter/readdirty.php?Cats=1")
        .then((res) => res.json())
        .then((result) => {
            this.setState({
                categories: result,
            });
            
            fetch(window.server + "api/cropper/readone.php?Cats=1").then(res => res.json()).then(result => {
                this.setState({
                    cleanCats: result,
                    isloading: false,
                });
            })
        });
    }


    updateInputFolder = () => {
        
        this.setState({
            isloading: true,
        });

        const category = this.state.category;
        const cleanCat = this.state.cleanCat;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                category:category,
                cleanCat:cleanCat
            })
        };
        fetch(window.server + "api/categories/move.php", requestOptions).then(res => res.json()).then(result => {
            if(result.result)
            {
                alert(category + " has been changed to " + cleanCat);
            }else{
                alert("Failed to change the names")
            }
        }).then(() => {
            fetch(window.server + "api/sorter/readdirty.php?Cats=1")
            .then((res) => res.json())
            .then((result) => {
                this.setState({
                    categories: result,
                });
                
                fetch(window.server + "api/cropper/readone.php?Cats=1").then(res => res.json()).then(result => {
                    this.setState({
                        cleanCats: result,
                        isloading: false,
                    });
                })
            });
        }).finally(() => {
            this.setState({
                isloading: false,
            });
        });
    }

    updatecat = (e) => {
        let cat = e.target.value;
        this.setState({
            category: cat,
        });
    };
    updateCleanCat = (e) => {
        let cat = e.target.value;
            this.setState({
            cleanCat: cat,
        });
    };

  render() {
    const theWholeField = (
        <div className="objectField">
            <div className="mainbody">
                <div className="loadingBar">
                    <div className="loadingnon">
                        <div style={{ margin: "0 auto", width: "64px" }}>
                            <ReactLoading
                            type="spin"
                            color={this.state.isloading ? "red" : "white"}
                            />
                        </div>
                    </div>
                    {/* <button>Add Disease To Clean Categories</button> */}
                    <p>Uploaded Folder Name</p>
                    <CatSelect
                    nullspace={"Uploaded Folder Categories"}
                    updatecat={this.updatecat}
                    cat={this.state.category}
                    categories={this.state.categories}
                    />
                    <p>Clean Folder Name</p>
                    <CatSelect
                    nullspace={"Clean Categories"}
                    updatecat={this.updateCleanCat}
                    cat={this.state.cleanCat}
                    categories={this.state.cleanCats}
                    />
                    <p></p>
                    <button onClick={this.updateInputFolder}>Rename The Input Folder To The Clean Folder</button>
                </div>
            </div>
        </div>
    );
    return theWholeField;
  }
}

export default RenameInputFolders;
