import './imagecropper.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import ImageView from './imagecropperhelper';
import './global';

//C:\Program Files\Ampps\www\SkinExam\AI-Server\image-managment
//C:\Program Files\Ampps\www\SkinExam\Automation\testing\analytics


function Cropper(props) {
    const [loadNewOne,setNewOne] = useState(0);
    const [loading,setLoading] = useState(false);
    const [src,setSrc] =  useState([]);
    const [image,changeImage] = useState(0);
    const [lastimage,changeLastImage] = useState(0);
    const [cats, setcategories] = useState([])
    // const [meta,setMeta] = useState({width:10,height:10});//getMeta(window.server + "MovedEdit/" + src[image] + '?' + new Date());

    // const [selectedimagepath,setSelectedImagePath] = useState("")
    const [cat,setCat] = useState("")
    // const [row,setRow] = useState([])
    const [page,setPage] = useState(0)
    // const [length,setLength] = useState(10)
    const length = 10;

    useEffect(() => {
        // props.setMenu(false);
        // function getMeta(url) {   
        //     var img = new Image();
        //     img.src = url;
        //     let wd = img.width;
        //     let ht = img.height;
        //     let obj = {width:wd,height:ht}
        //     return obj;
        // }
        setLoading(true);
        fetch(window.server + "api/cropper/readone.php?Cats=1").then(res => res.json()).then(result => {
            setcategories(result)
        })

        fetch(window.server + "api/cropper/read.php").then(res => res.json()).then(result => {
            console.log(result);
            let newestimages = [...new Set([...result.out.map(img => img.substring("./../../".length))])]
            setSrc(newestimages);
            changeLastImage(result.out[0])
            setPage(1);
        // }).then(() => {
            // if(src[image] !== undefined)
            // {
            //     setMeta(getMeta(window.server + "imagedirty/" + src[image] + '?' + new Date()));
            // }
        }).finally(() => {
            setLoading(false);
        })
    },[])


    const [crop, setCrop] = useState({
        unit: 'px', // Can be 'px' or '%'
        // x: x,
        // y: y,
        // width: (wd>ht?ht:wd),
        // height: (wd>ht?ht:wd)
    });


    function submit() {
        setLoading(true);
        const x = crop.x;
        const y = crop.y;
        const w = crop.width;
        const h = crop.height;

        let sendableimage = src[image].replace("imageclean/","")
        if(sendableimage.indexOf("?")>-1)
        {
            sendableimage = sendableimage.substring(0,sendableimage.indexOf("?"))
        }

        const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        x:x,
                        y:y,
                        w:w,
                        h:h,
                        image:sendableimage
                    })
                };
        document.body.style.cursor='wait';
        if(x !== undefined && y !== undefined && w !== undefined && h !== undefined)
        {
            fetch(window.server + "api/cropper/crop.php", requestOptions).then(res => res.json()).then(result => {

                if(result.result)
                {
                    setCrop({
                        unit: 'px', // Can be 'px' or '%'
                    });
                }
            }).then(() => {
                moveToNextImageNow(image);
            }).finally(() => {
                document.body.style.cursor='default';
                setLoading(false);
            });
        }else{
            console.log("nothing selected");
            moveToNextImageNow(image);        
            document.body.style.cursor='default';
            setLoading(false);
        }
    }

    // function undue(image) {
    //     setLoading(true);
    //     const requestOptions = {
    //         method: 'PUT',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             image:image
    //         })
    //     };
    //     fetch(window.server + "cropper/undo.php", requestOptions).then(res => res.json()).then(result => {
    //         if(result.result)
    //         {
    //             setNewOne(loadNewOne + 1);
    //         }else{
    //             alert("This is image is the base image");
    //         }
    //     }).then(() => {
    //         setLoading(false);
    //     });
    // }
    
    
    // function getIntersect(first,second)
    // {
    //     const result = first.filter(cert => {
    //         let arr = second.filter(detail => detail === cert)
    //         return !(arr.length === 0)
    //     });
    //     return result
    // }

    function moveToNextImageNow(image){
        console.log("Image Next: " + image)
        if(isInt(image))
        {
            let hold = [...src];
            let test = hold[image].includes("?");
            hold[image] = (test)?hold[image].substring(0,hold[image].indexOf("?")) + "?" + new Date():hold[image] + "?" + new Date();
            
            changeLastImage(window.server + "/" + hold[image]);
            setSrc(hold);
            setNewOne(image);
            changeImage(image+1);
            setNewOne(image+1);
            if(image+3 >= hold.length)
            {
                addNewImages()
            }

        }else{

            let image1 = image.lastIndexOf('/');
            let image2 = image.substring(0,image1);
            let image3 = image2.lastIndexOf('/') + 1;
            let image4 = image.substring(image3);
            let img = image4.replace(/%20/g, " ");
            let index = src.findIndex(url => url.includes(img.substring(0,img.indexOf("?"))));
            let hold = [...src];
            let test = hold[index].includes("?");
            (test)?
            hold[index] = hold[index].substring(0,hold[index].indexOf("?")) + "?" + new Date():
            hold[index] = hold[index] + "?" + new Date();
            // console.log({Image:image, Hold:hold, Index:index})
            changeLastImage(image);
            setSrc(hold);
            changeImage(index+1);
            setNewOne(index+1);
            if(index+3 > hold.length)
            {
                addNewImages()
            }
        }        
    }
    function moveToPrevImageNow(image){
        if(isInt(image))
        {
            
            let hold = [...src];
            let test = hold[image].includes("?");
            // console.log("Image: " + image + " Test: " + test + " Hold: " + hold[image])
            hold[image] = (test)?hold[image].substring(0,hold[image].indexOf("?")) + "?" + new Date():hold[image] + "?" + new Date();
            
            // console.log("hold: " + hold[image])
            changeLastImage(hold[image]);
            setSrc(hold);
            changeImage(image);
            setNewOne(image);
            changeImage(image-1);
            setNewOne(image-1);
        }else{

            let image1 = image.lastIndexOf('/');
            let image2 = image.substring(0,image1);
            let image3 = image2.lastIndexOf('/') + 1;
            let image4 = image.substring(image3);
            let img = image4.replace(/%20/g, " ");
            let index = src.findIndex(url => url.includes(img.substring(0,img.indexOf("?"))));
            let hold = [...src];
            let test = hold[index].includes("?");
            (test)?
            hold[index] = hold[index].substring(0,hold[index].indexOf("?")) + "?" + new Date():
            hold[index] = hold[index] + "?" + new Date();
            // console.log(getIntersect(src,hold))
            changeLastImage(image);
            setSrc(hold);
            changeImage(index);
            setNewOne(index);
            changeImage(index-1);
            setNewOne(index-1);
        }        
    }
      
      
    function changeImageNow(image){
        console.log("Image Change: " + image)
        if(isInt(image))
        {
            
            let hold = [...src];
            let test = hold[image].includes("?");
            (test)?
            hold[image] = hold[image].substring(0,hold[image].indexOf("?")) + "?" + new Date():
            hold[image] = hold[image] + "?" + new Date();
            changeLastImage(hold[image]);
            setSrc(hold);
            changeImage(image);
            setNewOne(image);
            if(image+3 >= hold.length)
            {
                addNewImages()
            }
        }else{

            let image1 = image.lastIndexOf('/');
            // console.log(image1);
            let image2 = image.substring(0,image1);
            // console.log(image2);
            let image3 = image2.lastIndexOf('/') + 1;
            // console.log(image3);
            let image4 = image.substring(image3);
            // console.log(image4);
            let img = image4.replace(/%20/g, " ");

            if(img.includes('?'))
            {
                img = img.substring(0,img.indexOf("?"))
            }

            let index = src.findIndex(url => url.includes(img));
            let hold = [...src];
            let test = hold[index].includes("?");
            (test)?
            hold[index] = hold[index].substring(0,hold[index].indexOf("?")) + "?" + new Date():
            hold[index] = hold[index] + "?" + new Date();
            // console.log({Image:image, Hold:hold, Index:index})
            changeLastImage(image);
            // setSelectedImagePath(image);
            setSrc(hold);
            changeImage(index);
            setNewOne(index);
            if(index+3 >= hold.length)
            {
                addNewImages()
            }
        }        
    }

    function isInt(value) {
        return !isNaN(value) && 
               parseInt(Number(value)) === value && 
               !isNaN(parseInt(value, 10));
    }


    function updateCat(cat)
    {
        setLoading(true);
        setCat(cat);
        fetch(window.server + "api/cropper/readone.php?Cat=" + cat + "&" + new Date()).then(res => res.json()).then(result => {
            setSrc(result.out.map(el => el.substring("./../../".length) + "?" + new Date()));
            setPage(1)
        }).finally(() => {
            setLoading(false);
        });
    }

    
    function addNewImages() {
        setLoading(true);
        document.body.style.cursor='wait !important';
        fetch(window.server + "api/cropper/readone.php?Cat=" + cat + "&length=" + length + "&page=" + page).then(res => res.json()).then(result => {
            console.log(result.out);
            let newestimages = [...new Set([...src,...result.out.map(img => img.substring("./../../".length))])]
            
            setSrc(newestimages)
            setPage(page + 1)
        }).finally(() => {
            document.body.style.cursor='default';
            setLoading(false)
        });
    }


    return (
        <div className='objectField'>
            <div className='controls'>
                <>
                    <button onClick={() => submit()}>Submit</button>
                </>
                <>
                    <button onClick={() => ((image - 1)>-1?moveToPrevImageNow(image):0)}>Prev</button>
                    <button onClick={() => ((image + 1)<src.length)?moveToNextImageNow(image):null}>Next</button>
                </>
                <>        
                    <p>
                        {src[image]?src[image].substring(src[image].indexOf("/"),src[image].lastIndexOf("/")):null}
                    </p>
                </>
                <>
                    {(loading?
                    <div className='balls'>
                        <ReactLoading type={"balls"} color={"red"} />
                    </div>
                    :
                    <ReactCrop crop={crop} onChange={c => setCrop(c)} aspect={64/64}>
                        <img id="displayimg" className='viewimg' src={window.server + src[image] + "?" + new Date()} style={{}} alt={"skin"}/>
                    </ReactCrop>
                    )}
                </>
            </div>
            <div className='controlx'>
                <ImageView 
                    addNewImages={addNewImages}
                    updateCat={updateCat}
                    setselectedimage={changeImageNow}
                    cats={cats}
                    cat={cat}
                    imagecount={loadNewOne} 
                    src={src} 
                    passedImage={image} 
                    lastimage={lastimage}>
                </ImageView>
            </div>
        </div>
    )
}


export default Cropper;
