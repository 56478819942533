import './imagecropper.css';
import 'react-image-crop/dist/ReactCrop.css';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import ImageView from './imagecropperhelper';
import './global';

//C:\Program Files\Ampps\www\SkinExam\AI-Server\image-managment
//C:\Program Files\Ampps\www\SkinExam\Automation\testing\analytics


function CleanReview(props) {
    const [loadNewOne,setNewOne] = useState(0);
    const [loading,setLoading] = useState(false);
    const [src,setSrc] =  useState([]);
    const [image,changeImage] = useState(0);
    const [lastimage,changeLastImage] = useState(0);
    const [cats, setcategories] = useState([])
    // const [meta,setMeta] = useState({width:10,height:10});//getMeta(window.server + "MovedEdit/" + src[image] + '?' + new Date());

    // const [selectedimagepath,setSelectedImagePath] = useState("")
    const [cat,setCat] = useState("")
    // const [row,setRow] = useState([])
    const [page,setPage] = useState(0)
    // const [length,setLength] = useState(10)
    const length = 100;

    useEffect(() => {
        setLoading(true);
        fetch(window.server + "api/cleanreview/readone.php?Cats=1").then(res => res.json()).then(result => {
            setcategories(result)
        }).finally(() => {
            setLoading(false);
        })
    },[])





      
    function changeImageNow(image){
        console.log("Image Change: " + image)
        if(isInt(image))
        {
            
            let hold = [...src];
            let test = hold[image].includes("?");
            (test)?
            hold[image] = hold[image].substring(0,hold[image].indexOf("?")) + "?" + new Date():
            hold[image] = hold[image] + "?" + new Date();
            changeLastImage(hold[image]);
            setSrc(hold);
            changeImage(image);
            setNewOne(image);
            if(image+3 >= hold.length)
            {
                addNewImages()
            }
        }else{

            let image1 = image.lastIndexOf('/');
            // console.log(image1);
            let image2 = image.substring(0,image1);
            // console.log(image2);
            let image3 = image2.lastIndexOf('/') + 1;
            // console.log(image3);
            let image4 = image.substring(image3);
            // console.log(image4);
            let img = image4.replace(/%20/g, " ");

            if(img.includes('?'))
            {
                img = img.substring(0,img.indexOf("?"))
            }

            let index = src.findIndex(url => url.includes(img));
            let hold = [...src];
            let test = hold[index].includes("?");
            (test)?
            hold[index] = hold[index].substring(0,hold[index].indexOf("?")) + "?" + new Date():
            hold[index] = hold[index] + "?" + new Date();
            // console.log({Image:image, Hold:hold, Index:index})
            changeLastImage(image);
            // setSelectedImagePath(image);
            setSrc(hold);
            changeImage(index);
            setNewOne(index);
            if(index+3 >= hold.length)
            {
                addNewImages()
            }
        }        
    }

    function isInt(value) {
        return !isNaN(value) && 
               parseInt(Number(value)) === value && 
               !isNaN(parseInt(value, 10));
    }


    function updateCat(cat)
    {
        setLoading(true);
        setCat(cat);
        fetch(window.server + "api/cleanreview/readone.php?Cat=" + cat + "&" + new Date()).then(res => res.json()).then(result => {
            setSrc(result.out.map(el => el.substring("./../../".length) + "?" + new Date()));
            setPage(1)
        }).finally(() => {
            setLoading(false);
        });
    }

    
    function addNewImages() {
        setLoading(true);
        document.body.style.cursor='wait !important';
        fetch(window.server + "api/cleanreview/readone.php?Cat=" + cat + "&length=" + length + "&page=" + page).then(res => res.json()).then(result => {
            console.log(result.out);
            let newestimages = [...new Set([...src,...result.out.map(img => img.substring("./../../".length))])]
            
            setSrc(newestimages)
            setPage(page + 1)
        }).finally(() => {
            document.body.style.cursor='default';
            setLoading(false)
        });
    }


    return (
        <div className='objectField'>
            {(loading?
                    <div className='balls'>
                        <ReactLoading type={"balls"} color={"red"} />
                    </div>
                    :<div className='balls'></div>)}
            <div className='controlw'>
                <ImageView 
                    addNewImages={addNewImages}
                    updateCat={updateCat}
                    setselectedimage={changeImageNow}
                    cats={cats}
                    cat={cat}
                    imagecount={loadNewOne} 
                    src={src} 
                    passedImage={image} 
                    lastimage={lastimage}>
                </ImageView>
            </div>
        </div>
    )
}


export default CleanReview;
