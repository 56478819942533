import React from "react";
import ImageThumb from "./imagespeedsorterimagethumb";


class ImageViewer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }
    render() {
      const theWholeField = (
        <div className="selectedimage">
            <div>
                {
                this.props.alertSelected==="Delete"?<p id="deletealert" style={{display:'none'}}>Moved To Delete</p>:
                this.props.alertSelected==="Fix"?<p id="fixalert" style={{display:'none'}}>Moved To Fix</p>:
                this.props.alertSelected==="Correct"?<p id="correctalert" style={{display:'none'}}>Moved To Correct</p>:null}
                {/* <img id="displayimg" alt={""} src={this.props.selectedimagepath} /> */}
            </div>
            <div className="selectedimagefield">
                {this.props.rows.map(img => {
                    return (<ImageThumb hoversetselectedimage={this.props.hoversetselectedimage} setselectedimage={this.props.setselectedimage} imageloc={img} thumbsize={this.props.thumbsize} />)
                })}
            </div>
        </div>
      );
      return theWholeField;
    }
  }
  

export default ImageViewer;