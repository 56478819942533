import React from "react";

class ToFolderSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const theWholeField = (
        <div className="tofolderselect">
            <div className="tofolderselectalt">
            <p>Send the selected Images to: </p>
            <select onChange={this.props.updateDis}>
                <option></option>
                {
                    this.props.cats.map(cat => {
                        return <option id={cat}>{cat}</option>
                    })
                }
            </select>
            <button onClick={this.props.submitSelected}>Commit Changes</button>
            <button onClick={this.props.deleteSelected}>Delete Selected</button>
            </div>
        </div>
    );
    return theWholeField;
  }
}

export default ToFolderSelect;