import React from "react";
import ImageWithToggle from "./imagediagnoserimage";

class ToDiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const theWholeField = (
      <div className="todiv" id="todiv">
        {
            this.props.images.map(image => {
                return (
                    <ImageWithToggle key={Math.random(50000)} fromimg={false} imageurl={image} selected={this.props.selected} selectedImages={this.props.selectedImages} toggleEdit={this.props.toggleEdit} updateSelected={this.props.updateSelectedOut}></ImageWithToggle>
                )
            })
        }
      </div>
    );
    return theWholeField;
  }
}

export default ToDiv;