import React from "react";
import FromDiv from "./imagediagnoserfrom";
// import ImageWithToggle from "./imagediagnoserimage";
import ToDiv from "./imagediagnoserto";
import ToFolderSelect from "./imagediagnosertofolderselect";
import ReactLoading from 'react-loading';
import './imagediagnoser.css';
import './global';

class SpeedSorter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        images:[],
        selectedImages:[],
        toedit:[],
        cats:[],
        disease:"",
        length:100,
        page:0,
        isloading:false
    }
  }


  componentDidMount = () => {
    // this.props.setMenu(false);
    this.setState({
        isloading:true
    })
    fetch(window.server + "api/diagnoser/readDelete.php?Cats=x&date=" + new Date()).then(res => res.json()).then(result => {
        console.log(result.out);
        this.setState({
            cats:result
        })
    }).then(() => {
        fetch(window.server + "api/diagnoser/readDelete.php?length=" + this.state.length + "&page=" + this.state.page + "&date=" + new Date()).then(res => res.json()).then(result => {
            console.log(result.out);
            let newImages = result.out.map(img => window.server + "imagedirty/" + img);
            this.setState({
                images:newImages,
                page:this.state.page + 1
            })
        });
    }).finally(() => {
        this.setState({
            isloading:false
        })
    });
    
  }

  infiniteScroll = (e) => {
    const target = e.target;
    if(target.scrollHeight - target.scrollTop <= target.clientHeight)
    {
        // alert("Scrolled");
        this.addNewImages();
    }
  }

  addNewImages = () => {
    this.setState({
        isloading:true
    })
    document.body.style.cursor='wait !important';
    fetch(window.server + "api/diagnoser/readDelete.php?length=" + this.state.length + "&page=" + this.state.page).then(res => res.json()).then(result => {
        console.log(result.out);
        let newImages = result.out.map(img => window.server + "imagedirty/" + img);
        let newestimages = [...new Set([...this.state.images,...newImages])]
        this.setState({
            images:newestimages,
            page:this.state.page + 1
        })
    }).finally(() => {
        document.body.style.cursor='default';
        this.setState({
            isloading:false
        })
    });
}
resetImages = () => {
    this.setState({
        isloading:true
    })
    document.body.style.cursor='wait !important';
    fetch(window.server + "api/diagnoser/readDelete.php?length=" + this.state.length + "&page=" + this.state.page).then(res => res.json()).then(result => {
        console.log(result.out);
        let newImages = result.out.map(img => window.server + "imagedirty/" + img);
        
        this.setState({
            images:newImages,
            page:this.state.page + 1,
            selectedImages:[],
            toedit:[],
        })
    }).finally(() => {
        document.body.style.cursor='default';
        this.setState({
            isloading:false
        })
    });
}

  updateSelected = e => {
    let targ = e.target.src;
    console.log(targ)
    this.setState({
        selectedImages: [...new Set([targ,...this.state.selectedImages])],
        // images: [...new Set(this.state.images.filter(e => e !== targ))]
    },() => {
        console.log(targ)
        console.log(this.state.images)
        console.log(this.state.selectedImages)
    })
    var myDiv = document.getElementById('todiv');
    myDiv.scrollTop = 0;
  }

  updateSelectedOut = e => {
    let targ = e.target.src;
    console.log(targ)
    this.setState({
        selectedImages: [...new Set(this.state.selectedImages.filter(e => e !== targ))]
    },() => {
        console.log(targ)
        console.log(this.state.images)
        console.log(this.state.selectedImages)
    })
  }

  toggleEdit = e => {
    let imageinrow = document.getElementById(encodeURI(e.target.name));
    if(imageinrow !== null)
    {
        imageinrow.parentElement.style.backgroundColor = "red";
        document.getElementById("todiv").scrollTop = imageinrow.offsetTop - 64;    
    }

    if(e.target.checked)
    {
        this.setState({
            toedit:[...this.state.toedit,e.target.name]
        })
    }else{
        this.setState({
            toedit:this.state.toedit.filter(x => x !== e.target.name)
        })
    }
  }
  deleteSelected = () => {
    const toedit = this.state.toedit;
    // const images = this.state.images;
    const selectedImages = this.state.selectedImages.filter(e => (toedit.indexOf(e) < 0));
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
        Files: selectedImages,
        Delete: "Delete",
        }),
    };
    fetch(window.server + "api/diagnoser/updateSelected.php", requestOptions)
    .then((res) => res.json())
    .then((result) => {
        if(result.result)
        {
            alert("Success");
        }else{
            alert("Failed");
        }
        }
    ).then(() => {
        this.setState({
            length:100,
            page:0
        },() => {
            this.resetImages();
        })
    });
  }
  
  submitSelected = () => {
    const toedit = this.state.toedit;
    // const images = this.state.images;
    const disease = this.state.disease;
    const selectedImages = this.state.selectedImages.filter(e => (toedit.indexOf(e) < 0));
    if(disease !== "")
    {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                // images:images,
                Disease: disease,
                SelectedImages: selectedImages.map(img => img.replace(window.server + "../imagedirty/","")),
                ToEdit:toedit.map(img => img.replace(window.server + "../imagedirty/","")),
            })
        };
        
        fetch(window.server + "api/diagnoser/updateSelected.php", requestOptions).then(res => res.json()).then(result => {
            if(result.result)
            {
                alert("Success");
            }else{
                alert("Failed");
            }
        }).then(() => {
            this.setState({
                length:100,
                page:0
            },() => {
                this.resetImages();
            })
        });
    }else{
        alert("Please Select a disease.");
    }
  }

  updateDis = e => {
    let targ = e.target.value;
    this.setState({
        disease:targ,
    })
  }

  render() {
    const theWholeField = (
        <div className='objectField'>
            {this.state.isloading?<div className="loading">
                <div style={{margin:"0 auto",width:"64px"}}>
                <ReactLoading type="spin" color={this.state.isloading?"red":"white"}/>
                </div>
            </div>:null}
            <ToDiv images={this.state.selectedImages} selected={this.state.toedit} selectedImages={this.state.selectedImages} toggleEdit={this.toggleEdit} updateSelectedOut={this.updateSelectedOut}></ToDiv>
            <FromDiv isloading={this.state.isloading} infiniteScroll={this.infiniteScroll} images={this.state.images} selectedImages={this.state.selectedImages} selected={this.state.toedit} toggleEdit={this.toggleEdit} updateSelected={this.updateSelected}></FromDiv>
            <ToFolderSelect deleteSelected={this.deleteSelected} submitSelected={this.submitSelected} cats={this.state.cats} updateDis={this.updateDis}></ToFolderSelect>
        </div>
    );
    return theWholeField;
  }
}

export default SpeedSorter;